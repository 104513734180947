<template>
  <!-- 登录页 -->
  <v-app>
    <v-main class="align-center background-color">
      <v-container fluid>
        <div class="platform-title mb-4 white--text">{{$t('lang.login.platform')}}</div>
        <v-card
          :max-width="$vuetify.breakpoint.xs ? 300 : 500"
          class="mx-auto py-10"
          :class="$vuetify.breakpoint.xs ? 'px-6' : 'px-16'"
        >
          <!-- 登录表单 -->
          <div v-if="showLoginInfo">
            <v-card-title class="justify-center login-title mb-5">{{$t('lang.login.title')}}</v-card-title>
            <v-card-text class="text-end">
              <v-form ref="loginForm">
                <v-text-field outlined autofocus :label="$t('lang.register.usernameLabel')" v-model="loginForm.username" :rules="usernameRules"></v-text-field>
                <v-text-field type="password" outlined :label="$t('lang.register.passwordLabel')" v-model="loginForm.password" :rules="passwordRules" @keyup.enter="login()"></v-text-field>
              </v-form>
              <v-btn text small class="mt-n10" @click="showLoginInfo=false;setPhoneNumber=true">{{$t('lang.login.forgotPassword')}}</v-btn>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn class="white--text" color="rgb(0, 110, 255)" @click="login()">{{$t('lang.login.loginBtn')}}</v-btn>
            </v-card-actions>
            <div class="mt-2 text-center">
              <v-btn text small color="rgb(0, 110, 255)" @click="toRegister()">{{$t('lang.login.toRegisterBtn')}}</v-btn>
            </div>
          </div>
          <!-- 重设密码 -->
          <div v-else>
            <v-card-title class="justify-center login-title">{{$t('lang.login.resetPassword.title')}}</v-card-title>
            <!-- 填写手机号 -->
            <div v-if="setPhoneNumber">
              <v-card-subtitle class="subtitle-1">1. {{$t('lang.login.resetPassword.phoneNumberVerification')}}</v-card-subtitle>
              <v-card-text>
                <v-form ref="codeForm">
                  <v-text-field type="number" outlined autofocus :label="$t('lang.register.phoneNumberLabel')" v-model="codeForm.phoneNumber" :rules="phoneNumberRules"></v-text-field>
                  <v-text-field type="number" outlined :label="$t('lang.register.verificationCodeLabel')" v-model="codeForm.verificationCode" :rules="verificationCodeRules">
                    <template v-slot:append-outer>
                      <v-btn @click="getVerificationCode()" :disabled="verificationCodeBtnAble">{{ verificationCodeBtnAble ? verificationCodeBtnlabel : $t('lang.register.verificationCodeBtn') }}</v-btn>
                    </template>
                  </v-text-field>
              </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  class="white--text"
                  color="rgb(0, 110, 255)"
                  @click="nextStep()"
                >{{$t('lang.login.resetPassword.nextStep')}}</v-btn>
              </v-card-actions>
            </div>
            <!-- 密码及确认密码 -->
            <div v-else>
              <v-card-subtitle class="subtitle-1">2. {{$t('lang.login.resetPassword.enterPassword')}}</v-card-subtitle>
              <v-card-text>
                <v-form ref="pwdForm">
                  <v-text-field type="password" outlined :label="$t('lang.register.passwordLabel')" v-model="pwdForm.password" :rules="resetPasswordRules" :counter="25"></v-text-field>
                  <v-text-field type="password" outlined :label="$t('lang.register.confirmPwdLabel')" v-model="pwdForm.confirmPwd" :rules="confirmPwdRules" :counter="25"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn class="white--text" color="rgb(0, 110, 255)" @click="finish()">{{$t('lang.login.resetPassword.finishBtn')}}</v-btn>
              </v-card-actions>
            </div>
            <div class="mt-2 text-center">
              <v-btn text small color="rgb(0, 110, 255)" @click="showLoginInfo=true;">{{$t('lang.login.resetPassword.backToLogin')}}</v-btn>
            </div>
          </div>
        </v-card>

        <div class="copyright white--text" :style="{'width': $i18n.locale == 'en-US' ? '161px' : '112px'}">© 2022 {{$t('lang.register.weiHaiWuZhou')}}</div>
      </v-container>
    </v-main>
    <v-snackbar :timeout="tips.timeout" v-model="tips.showSnackbar" :color="tips.snackbarColor" :top="true">{{ tips.snackbarText }}</v-snackbar>
  </v-app>
</template>
<script>
import axios from 'axios'
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl
import qs from "qs";
let vm;
export default {
  data() {
    return {
      showLoginInfo: true,
      setPhoneNumber: false,
      loginForm: {
        username: '',
        password: ''
      },
      usernameRules: [
        v => !!v || this.$t('lang.register.usernameRules.required')
      ],
      passwordRules: [
        v => !!v || this.$t('lang.register.passwordRules.required')
      ],
      verificationCodeBtnAble: false,
      verificationCodeBtnlabel: '',
      codeForm: {
        phoneNumber: '',
        verificationCode: ''
      },
      phoneNumberRules: [
        v => !!v || this.$t('lang.register.phoneNumberRules.required'),
        v => /^1[3456789]\d{9}$/.test(v) || this.$t('lang.register.phoneNumberRules.formatValidation')
      ],
      verificationCodeRules: [
        v => !!v ||  this.$t('lang.register.verificationCodeRules.required'),
      ],
      pwdForm: {
        password: '',
        confirmPwd: ''
      },
      resetPasswordRules: [
        v => !!v || this.$t('lang.register.passwordRules.required'),
        v => v.length >= 16 || this.$t('lang.register.passwordRules.minimumCharacterLimit'),
        v => v.length <= 25 || this.$t('lang.register.passwordRules.maximumCharacterLimit'),
        v => /^(?=.*[0-9])(?=.*[a-zA-Z])(.{16,25})$/.test(v) || this.$t('lang.register.passwordRules.specialCharacterRestriction'),
      ],
      confirmPwdRules: [
        v => !!v || this.$t('lang.register.confirmPwdRules.required'),
        v => v.length >= 16 || this.$t('lang.register.confirmPwdRules.minimumCharacterLimit'),
        v => v.length <= 25 || this.$t('lang.register.confirmPwdRules.maximumCharacterLimit'),
        v => /^(?=.*[0-9])(?=.*[a-zA-Z])(.{16,25})$/.test(v) || this.$t('lang.register.confirmPwdRules.specialCharacterRestriction'),
        v => v == this.pwdForm.password || this.$t('lang.register.confirmPwdRules.passwordConsistency')
      ],
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1
      }
    };
  },
  created() {
    vm = this;
  },
  methods: {
    toRegister() {
      this.$router.push("/register");
    },
    login() {
      if(!this.$refs.loginForm.validate()) return
      axios.post(`${apiUrl}login`, qs.stringify(this.loginForm)).then(res => {
        console.log(res)
        if(res.status == 200) {
          localStorage.token = res.data.access_token;
          localStorage.refresh_token = res.data.refresh_token;
          localStorage.username = vm.loginForm.username
          //if(this.$root.authority == 'ROLE_FINANCIAL'){
          //  vm.$router.push("/purchaseHistory");
          //}else{
            vm.$router.push("/");
          //}
          return
        }
        vm.showTips(vm.$t('lang.tips.login.failed'), 'error')
      }).catch(error => {
        console.log(error)
        vm.showTips(vm.$t('lang.tips.login.failed'), 'error')
      })
    },
    //通知提示工具
    showTips (text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if(timeout) {
        this.tips.timeout = timeout
      }else{
        this.tips.timeout = 3000
      }
    },
    //获取验证码
    getVerificationCode() {
      if(!this.codeForm.phoneNumber){
        this.showTips(this.$t('lang.register.phoneNumberRules.required'), 'error')
        return
      }
      //点击获取验证码后 60s后才能再次获取
      this.verificationCodeBtnAble = true
      let seconds = 60
      this.verificationCodeBtnlabel = `${this.$t('lang.register.resendverificationCode')}(${seconds})`
      let interval =  setInterval(() =>{
        seconds -= 1
        if(seconds <= 0){
          vm.verificationCodeBtnAble = false
          clearInterval(interval)
        }
        vm.verificationCodeBtnlabel = `${this.$t('lang.register.resendverificationCode')}(${seconds})`
      }, 1000)

      this.sendSMSCode()
    },
    nextStep() {
      if(!this.$refs.codeForm.validate()) return
      this.setPhoneNumber = false;
    },
    //修改密码
    finish() {
      if(!this.$refs.pwdForm.validate()) return
      let param = {
        newPassword: this.pwdForm.password,
        smsCode: this.codeForm.verificationCode
      }
      axios.post(`${apiUrl}changePassword`, qs.stringify(param)).then(res => {
        if(res.status == 200) {
          this.showTips(this.$t('lang.login.resetPassword.resetSuccess'), 'success')
          this.showLoginInfo = true
          return
        }
      }).catch(error => {
        console.log(error)
        this.showTips(this.$t('lang.login.resetPassword.resetFail'), 'error')
      }) 
    },
    //发送验证码
    async sendSMSCode() {
      await axios.post(`${apiUrl}sendSMSCode`, qs.stringify({phone: this.codeForm.phoneNumber})).then(res => {
        if(res.status == 200) {
          this.showTips(this.$t('lang.register.sendSuccess'), 'success')
          return
        }
        this.showTips(this.$t('lang.register.sendFail'), 'error')
      }).catch(error => {
        console.log(error)
        this.showTips(this.$t('lang.register.sendFail'), 'error')
      })
    }
  },
};
</script>
<style scoped>
.background-color {
  background: rgb(77, 139, 255);
}
.login-title {
  color: rgb(0, 110, 255);
  font-weight: bold;
  font-size: 24px;
  font-family: "Microsoft YaHei";
}
.platform-title {
  text-align: center;
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 28px;
}
.copyright {
  font-size: 14px;
  font-family: "Microsoft YaHei";
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
</style>